import { getBrandLogo, getLoginName, getProfilePic } from "../../comman/function";

export const INITIAL = [];
export const userDetails = {
  profilePic: getProfilePic(),
  userName: getLoginName(),
};
export const teamDetails = {
  companyLogo: getBrandLogo(),
};
