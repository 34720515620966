import React from "react";
import { Navigate } from "react-router-dom";
import { DASHBOARD_ROUTE, SIGNIN_ROUTE } from "../constants/RoutePaths";
import { getAuthToken, twoFAuth } from "./function";

export function PublicRoute({ children }) {
  const token = getAuthToken();
    return !token ? children : <Navigate to={DASHBOARD_ROUTE} />;
}

export function PrivateRoute({ children }) {
  const token = getAuthToken();
  const location = window.location.href;
  return token ? children : <Navigate to={SIGNIN_ROUTE} state={location} />;
}
export function authHeader() {
  const token = getAuthToken();
  return { Authorization: token };
}
export const getAlertToast = (type = "success", text = "", timer = 5000) => ({
  toast: true,
  position: "bottom",
  titleText: text,
  type: type,
  showConfirmButton: false,
  timer: timer,
});

export const getAlert = (type = "success", text = "") => ({
  position: "center",
  type: type,
  text: text,
  customClass: {
    confirmButton: "btn-shadow btn btn-primary btn-lg",
  },
  buttonsStyling: false,
  confirmButtonColor: "#000",
});
