import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, FormHelperText, Grid, OutlinedInput, TextField, Typography } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import defaultprofile from "../../images/defaultprofile.jpg";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import profileimage from "../../images/Group 2026.png";
import * as Yup from "yup";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
  getUserId,
} from "../../comman/function";
import { makeStyles } from "@mui/styles";
import {
  GET_TEAMDETAILS_BY_ID,
  GET_USER_BY_ID,
  UPLOAD_FILE,
  UPDATE_USER,
  GENERATE_OTP,
  VERIFY_OTP,
} from "../../comman/url";
import { useDispatch, useSelector } from "react-redux";
import { authHeader } from "../../comman/mainfunction";
import API from "../../constants/API";
import { styled } from "@mui/material/styles";
import { Form, useFormik } from "formik";
import { UPDATE_USER_PROFILE ,ISUPDATED} from "../../redux/action/ActionType";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../../comman/helperfunction";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import VerifyPhoneNumber from "../Modal/VerifyPhoneNumber";
import { selectUnstyledClasses } from "@mui/base";


const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles((theme) => ({
  verifybutton: {
    textTransform: "none !important",
    padding: "0px 0px 0px 16px !important",
    color: "#EC7C37 !important",
    fontWeight: "500px !important",
    "&:disabled": {
      color: "#A0A3BD !important",
    },
  },
  uploadbtn: {
    backgroundColor: "unset !important",
    border: "1px solid #3779EC !important",
    color: "#3779EC !important",
    boxShadow: "none !important",
  },
  phonenumber: {
    padding: "1.15rem !important",
  },
  profiledescription: {
    fontSize: "18px",
    letterSpacing: "0px",
    color: "#A0A3BD !important",
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative", marginRight: "5px" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={15}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          top: "10px",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={15}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const EditProfile = (props) => {
  const { classes } = props;
  const styleclasses = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [uplLoading, setUplLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);
  const [test, setTest] = useState(false)
  const isUploaded = useSelector((s) => s.DashboardReducer);

  const verifytoggle = () => {
    setVerifyModal(!verifyModal);
  };
  const [reCall, setreCall] = useState(0);
  const [code, setCode] = useState("");
  const [imageCount, setImageCount] = useState(0);

  const [snum, setSnum] = useState();
  const [phoneChange, setPhoneChange] = useState(false);
  const [firstName1,setFirstName1] = useState()
  const [lastName1,setLastName1] = useState()
  const [phoneNumber1,setPhoneNumber1] = useState() 
  const [countryCode1,setCountryCode1] = useState()

  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    userId: getUserId(),
    profileLogo: "",
  });

  useEffect(() => {
    GetUserById();
  }, [reCall]);

  const GetUserById = async () => {
    const config = {
      method: "GET",
      url: GET_USER_BY_ID,
      headers: authHeader(),
      params: {
        //userId: getUserId(),
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status, data, message } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, message } = JSON.parse(l);
      if (status === "Success") {
        setCode(data[0].countryCode);
        setSnum(data[0].phoneNumber);
        setFirstName1(data[0].firstName);
        setLastName1(data[0].lastName);
        setPhoneNumber1(data[0].phoneNumber);
        setCountryCode1(data[0].countryCode);



        data[0].phoneNumber = data[0].countryCode + "" + data[0].phoneNumber;
        setProfileData(data[0]);
      } else {
        CustomMessage(message, "error", enqueueSnackbar); //call toster
      }
    } catch (error) {
      CustomMessage(error, "error", enqueueSnackbar); //call toster

      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      //  setLoading(false);
    }
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required").max("20", "Only 20 characters maximum is allowed in this field!"),
    lastName: Yup.string().required("Required").max("20", "Only 20 characters maximum is allowed in this field!"),
    // .nullable(),
    // phoneNumber: Yup.string().required("Required"),
    // .matches(phoneRegExp, "Invalid"),
  });

  let EditUser = async (values) => {
    setTest(false)
    
    let {
      userId,
      firstName,
      lastName,
      phoneNumber,
      profileLogo,
      approvalAmountFrom,
      approvalAmountTo,
      countryCode,
      userRole
    } = values;

    // if (!phoneNumber) {
    //   Swal.fire(getAlert("error", "Phone number required"));
    // } else {

    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      if (phoneNumber) {
        const config = {
          method: "PUT",
          url: UPDATE_USER,
          data: {
            webString: enCryptFun(
              JSON.stringify({
                userId,
                firstName: firstName.trim(),
                countryCode: code,
                lastName: lastName.trim(),
                phoneNumber: snum,
                profileLogo,
                approvalAmountFrom,
                approvalAmountTo,
                userRole
              })
            ),
            flutterString: "",
          },
          headers: authHeader(),
        };
        try {
          setLoading(true);
          const response = await API(config);
          //const { status } = response.data;
          let l = deCryptFun(response.data);
          const { status } = JSON.parse(l);
          if (status === "Success") {
            GetUserById();
            setPhoneChange(false);

            dispatch({
              type: UPDATE_USER_PROFILE,
              payload: profileLogo,
              userName: "" + firstName + " " + lastName + "",
            });
            localStorage.setItem(
              "LOGIN_NAME",
              "" + firstName + " " + lastName + "" ? enCryptFun(("" + firstName + " " + lastName + "").toString()) : ""
            );

            localStorage.setItem("PROFILE_PIC", profileLogo ? enCryptFun(profileLogo) : "");
            CustomMessage("Changes Saved: Your updates have been saved successfully.", "success", enqueueSnackbar); //call toster
          }
        } catch (error) {
          // let errorObj = Object.assign({}, error);
          // let { data } = errorObj.response;
          // let { message } = data;

          if (error.response) {
            let { data } = error.response;

            let p = deCryptFun(data);
            let v = JSON.parse(p);
            
            CustomMessage(v.message, "error", enqueueSnackbar); //call toster
          }
        } finally {
          setLoading(false);
        }
      } else {
        // Swal.fire(getAlert("error", "Please fill valid Phone number"));
        CustomMessage(
          "Please fill valid Phone number",
          "error",
          enqueueSnackbar
        ); //call toster
      }
    } else {
      if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
        CustomMessage(
          "Please enter a valid phone number",
          "error",
          enqueueSnackbar
        ); //call toster
        return null;
      } else {
        const config1 = {
          method: "PUT",
          url: UPDATE_USER,
          data: {
            webString: enCryptFun(
              JSON.stringify({
                userId,
                firstName: firstName.trim(),
                countryCode: "",
                lastName: lastName.trim(),
                phoneNumber: "",
                profileLogo,
                approvalAmountFrom,
                approvalAmountTo,
                userRole
              })
            ),
            flutterString: "",
          },
          headers: authHeader(),
        };
        try {
          setLoading(true);
          const response = await API(config1);
          //  const { status } = response.data;
          let l = deCryptFun(response.data);
          const { status } = JSON.parse(l);
          if (status === "Success") {
            GetUserById();
            dispatch({
              type: UPDATE_USER_PROFILE,
              payload: profileLogo,
              userName: "" + firstName + " " + lastName + "",
            });
            localStorage.setItem(
              "LOGIN_NAME",
              "" + firstName + " " + lastName + "" ? enCryptFun(("" + firstName + " " + lastName + "").toString()) : ""
            );
            localStorage.setItem("PROFILE_PIC", profileLogo ? enCryptFun(profileLogo) : "");
            // Swal.fire(getAlertToast("Success", "Updated Successfully"));
            CustomMessage("Changes Saved: Your updates have been saved successfully.", "success", enqueueSnackbar); //call toster
          }
        } catch (error) {
          if (error.response) {
            let { data } = error.response;
            let p = deCryptFun(data);
            let v = JSON.parse(p);
            
            // Swal.fire("Error", v.message);
            CustomMessage(v.message, "error", enqueueSnackbar); //call toster
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };
  const handleFileChange = async (e) => {
    e.persist();
    setImageCount(imageCount + 1);
    let file = e;
    if (!file.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
      // Swal.fire("Alert !", );
      CustomMessage(
        "Invalid file format - Only JPEG, JPG, and PNG are supported.",
        "error",
        enqueueSnackbar
      ); //call toster
    } else {
      let formdata = new FormData();
      var reader = new FileReader();
      reader.readAsDataURL(file.target.files[0]);
      reader.onload = async function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = async function () {
          var height = this.height;
          var width = this.width;
          // if (!file.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
          //   // Swal.fire("Alert !", );
          //   CustomMessage(
          //     "Please upload jpeg,jpg,png file format only.",
          //     "error",
          //     enqueueSnackbar
          //   ); //call toster
          // } else
          if (height > 1000 || width > 1000) {
            CustomMessage(
              "Invalid file - Must be PNG or JPG no larger than 1000 x 1000 px.",
              "error",
              enqueueSnackbar
            );
            // return false;
          } else {
            
            formdata.append("file", file.target.files[0]);
            try {
              setUplLoading(true);
              const option = {
                method: "POST",
                url: UPLOAD_FILE,
                data: formdata,

                headers: authHeader(),
                "content-type": "multipart/form-data",
              };
              let response = await API(option);
              const { status, filePath, message } = response.data;
              //  let l = deCryptFun(response.data);
              // const { status, filePath, message } = JSON.parse(l);
              if (status === "Success") {
                CustomMessage(message, "success", enqueueSnackbar); //call toster

                setProfileData({ ...values, profileLogo: filePath });
              }
            } catch (error) {
              if (error.response) {
                let { data } = error.response;

                let p = deCryptFun(data);
                let v = JSON.parse(p);
                
                
                CustomMessage(v.message, "error", enqueueSnackbar); //call toster
              }
            } finally {
              setUplLoading(false);
            }
          }
          // return true;
        };
      };
    }
  };
  const phoneNumberChange = (value) => {
    setValues({ ...values, phoneNumber: value });
    setPhoneChange(true);
    if (value) {
      const l = formatPhoneNumberIntl(value);
      const coden = l.split(" ")[0];
      setCode(coden);
      const number = value.split(coden)[1];
      setSnum(number);
    }
  };

  const successVerifyPhoneNumber = () => {
    setreCall(reCall + 1);
    setVerifyModal(false);
    setPhoneChange(false);
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    errors,
    touched,
  } = useFormik({
    enableReinitialize: true,
    initialValues: { ...profileData },
    validationSchema,
    onSubmit: (values) => {
      EditUser(values);
    },
  });


  useEffect(()=>{
    if(values.firstName != firstName1 || values.lastName != lastName1 || values.phoneNumber != `${countryCode1}${phoneNumber1}`){
      setTest(true)
    }else{
      setTest(false)
    }
  },[values.firstName, values.lastName, values.phoneNumber])

  useEffect(()=>{
    if(test){
      dispatch({ type: ISUPDATED, payload: true })
    } else{
      dispatch({ type: ISUPDATED, payload: false })
      
    }
  },[test])

  return (
    <>
      <Box
        component={"form"}
        onSubmit={handleSubmit}
        sx={{ maxWidth: 600, margin: "auto", mt: 4 }}
      >
        <img
          style={{
            width: "125px",
            height: "125px",
            borderRadius: "50%",
          }}
          src={values.profileLogo || defaultprofile}
        ></img>
        <Typography
          sx={{ fontWeight: 700, fontSize: "20px", mb: 1 }}
          variant="h5"
        >
          Add a Profile Photo
        </Typography>
        <Typography paragraph className={styleclasses.profiledescription}>
        Personalize your account and make it more recognizable by uploading a profile photo. We accept PNG or JPG files no larger than 1000 pixels tall and wide. For optimal display, 
          <span style={{ display: "block" }}>we recommend using a photo with a size of 200 x 50 pixels. To upload a photo, click 'Upload' and select a file from your device. If your file is not in the correct format or size, you can use free online tools to convert or resize it. Please note that your photo may be visible to other users in the application.</span>
        </Typography>
        <label htmlFor="contained-button-file" key={imageCount || ""}>
          <Input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleFileChange}
          />
          <Button
            className={styleclasses.uploadbtn}
            variant="contained"
            component="span"
            startIcon={
              <span style={{ display: "flex", alignItems: "center" }}>
                {uplLoading && <FacebookCircularProgress />}
                <FileUploadIcon />
              </span>
            }
          >
            Upload
          </Button>
        </label>
        <Box sx={{ mt: 4, textAlign: "left", display: "grid" }}>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              First Name
            </Typography>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <OutlinedInput
                type={"text"}
                value={values.firstName}
                error={
                  errors.firstName && touched.firstName
                    ? errors.firstName
                    : ""
                }
                onBlur={handleBlur}
                onChange={handleChange}
                name="firstName"
                placeholder="Enter First Name"
              />
              <FormHelperText error={true} data-testid="firstNameRequired">
                {errors.firstName && touched.firstName ? errors.firstName : ""}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              Last Name
            </Typography>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <OutlinedInput
                type="text"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lastName && touched.lastName ? true : false}
                placeholder="Enter Last Name"
                name="lastName"
              />
              <FormHelperText error={true} data-testid="lastNameRequired">
                {errors.lastName && touched.lastName ? errors.lastName : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              Email
            </Typography>
            <CssTextField
              fullWidth
              required
              id="outlined-required"
              disabled="true"
              value={values.email}
              name="email"
            />
          </Grid>

          <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
            Mobile Phone Number
          </Typography>

          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ display: "flex" }}>
              <Grid
                item
                // xs={getRole() === "Supplier" ? 12 : 8}
                // md={getRole() === "Supplier" ? 12 : 7}
                // lg={getRole() === "Supplier" ? 12 : 7}
                // sx={{ width: getRole() === "Supplier" ? "100%" : "80%" }}
                xs={8}
                md={7}
                lg={7}
                // sx={{ width: "80%" }}
                sx={{ width: "100%" }}
              >
                <PhoneInput
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  value={values.phoneNumber}
                  onChange={phoneNumberChange}
                  international="false"
                  className={styleclasses.phonenumber}
                  error={
                    values.phoneNumber
                      ? isValidPhoneNumber(values.phoneNumber)
                        ? "correct"
                        : "Invalid phone number"
                      : "Phone number required"
                  }
                />
              </Grid>
              {/* {getRole() !== "Supplier" && ( */}
              {/* <Grid item xs={4} md={5} lg={5} sx={{ alignSelf: "center" }}>
                {(values.isPhoneVerified == 0 || phoneChange) && (
                  //  || phoneChange
                  <Button
                    onClick={verifytoggle}
                    className={styleclasses.verifybutton}
                    disabled={phoneChange}
                  >
                    Verify by text
                  </Button>
                )}
              </Grid> */}
              {/* )} */}{" "}
            </Box>{" "}
            <Typography>
              {errors.phoneNumber && touched.phoneNumber
                ? errors.phoneNumber
                : // : !isValidPhoneNumber(values.phoneNumber)
                // ? "Invalid"
                ""}
            </Typography>
          </Grid>
        </Box>
        <Button
          sx={{
            mt: 3,
            backgroundColor: "#EC7C37",
            textTransform: "capitalize",
          }}
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          startIcon={
            <span style={{ display: "flex", alignItems: "center" }}>
              {loading && <FacebookCircularProgress />}
            </span>
          }
        >
          Save Changes
        </Button>
      </Box>
      <VerifyPhoneNumber
        open={verifyModal}
        addToggleClose={verifytoggle}
        value={values}
        code={code}
        successCloseToggle={successVerifyPhoneNumber}
      />
    </>
  );
};

export default EditProfile;
