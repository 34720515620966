import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  ModalFooter,
  Form,
  FormFeedback,
} from "reactstrap";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Typography, FormHelperText } from "@mui/material";
import { Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";

import CustomButtonLoader from "../../comman/CustomButtonLoader";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  deCryptFun,
  enCryptFun,
  getRole,
  getSubTeamId,
  getTeamID,
  getUserId,
} from "../../comman/function";
import {
  GET_INVOICE_FIELDS,
  INVOICELINE_CREATE_URL,
  INVOICE_LINE_UPDATE_URL,
  UPDATE_INV_PROCESS_TIME,
  SAVE_AUDIT,
} from "../../comman/url";
import { authHeader, getAlert, getAlertToast } from "../../comman/mainfunction";
import API from "../../constants/API";
import {
  NUMBER_REGEX,
  LETTERS_NUMBERS_SYMBOLS_SPACE,
  LETTERS_NUMBERS_SYMBOLS,
  NUMBERS_SYMBOLS,
  NUMBERS_AND_SYMBOLS_REGEX,
  LETTERS,
  NUMBERS_DOT_REGEX,
  NUMBER_TWO_DECIMAL,
} from "../../comman/constants";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import { getSorting, stableSort } from "../../comman/function";
import DatePicker from "react-datepicker";
import moment from "moment";
import { CustomMessage } from "../../comman/helperfunction";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Document, Page, pdfjs } from "react-pdf";
import Pagination from "@mui/material/Pagination";
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-error fieldset": {
      borderColor: "red",
    },
    "& .MuiFormHelperText-root": {
      borderColor: "green",
    },
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input": {
    border: "1px solid green",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    border: "1px solid green",
  },
});

const styles = {
  borderColor: "red 1px solid !important"
};

const acquireValidationColor = (message) => {
  switch (message) {
    case "Incorrect entry":
      return "green";
    case "Please input":
      return "orange";
    default:
      return "black";
  }
};
const useStyles = makeStyles((theme) => ({
  tools: {
    zIndex: 9,
    top: 0,
    left: 0,
  },
  previewbtn: {
    color: "#14142A !important",
  },
  popupclose: {
    position: "absolute",
    right: "13px",
    top: "0px",
    cursor: "pointer",
  },
  popupcloseicon: {
    fontSize: "30px",
    color: "gray",
  },
  paper: { maxWidth: "1400px", width: "100%" },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CreateInvoice(props) {
  const {
    createInvoiceModal,
    flag,
    invoiceToggle,
    formData,
    submitCallBack,
    invoiceID,
    dialogueView,
    type,
    GlCode,
    updateInvoiceLineItems,
    invoiceFilePath,
    extensionOfUrl,
    previewUrl,
    timeproccess,
    getAudit,
    invoiceListlen,
    editInvoiceLine,
    indexvalue
  } = props;
  
  
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const subTeamId = getSubTeamId();
  const [requiredFieldList, setRequiredFieldList] = useState([]);
  const [glDate, setGlDate] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const [gLDateRequiredError, setGlDateRequiredError] = useState(false);
  const [gLDateValidationError, setGlDateValidationError] = useState(false);
  const [glDateStyles, setGlDateStyles] = useState({ width: "100@ !important" })
  const [numPages, setNumPages] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const styleclasses = useStyles();
  
  const transformOptionsPdf = {
    defaultScale: 1,
    defaultPositionX: 200,
    defaultPositionY: 100,
  };
  const transformOptionsPng = {
    defaultScale: 1,
    defaultPositionX: 0,
    defaultPositionY: 0,
  };

  const validationSchema = Yup.object({
    // operatingUnit,invoiceLineType,itemDescription,itemNumber
    operatingUnit: Yup.string()
      //.matches(LETTERS_NUMBERS_SYMBOLS, "Invalid Operating Unit")
      .test("operatingUnitTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "operatingUnit" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),

    invoiceLineNumber: Yup.string()
      // .matches(NUMBERS_SYMBOLS, "Invalid Invoice Line Number")
      .test("invoiceLineNumberTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "invoiceLineNumber" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .test('invoiceLineNumberTest1', 'Invalid Invoice Line Number', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "invoiceLineNumber" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? NUMBERS_SYMBOLS.test(value) : true;
      })
      .nullable(),

    invoiceLineType: Yup.string()
      //.matches(LETTERS_NUMBERS_SYMBOLS, "Invalid Line Type")
      .test("invoiceLineTypeTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "invoiceLineType" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),

    extendedPrice: Yup.string()
      // .matches(NUMBERS_DOT_REGEX, "Invalid Extended Price")
      .test("extendedPriceTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "extendedPrice" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .test('extendedPriceTest1', 'Invalid Extended Price', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "extendedPrice" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? NUMBERS_DOT_REGEX.test(value) : true;
      })
      .nullable(),

    unitOfMeasure: Yup.string()
      // .matches(LETTERS, "Invalid Unit Of Measure")
      .test("unitOfMeasureTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "unitOfMeasure" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .test('unitOfMeasureTest1', 'Invalid Unit Of Measure', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "unitOfMeasure" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? LETTERS.test(value) : true;
      })
      .nullable(),
    itemDescription: Yup.string()
      .test("itemDescriptionTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "itemDescription" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })

      .nullable(),
    itemNumber: Yup.string()
      .test("itemNumberTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "itemNumber" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    poLineNumber: Yup.string()
      // .matches(NUMBER_REGEX, "Invalid Po Line Number")
      .test("poLineNumberTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "poLineNumber" &&
            element.isRequired === 1 &&
            element.isVisible === 1 &&
            type == "po"
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .test('poLineNumberTest1', 'Invalid Po Line Number', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "poLineNumber" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? NUMBER_REGEX.test(value) : true;
      })
      .nullable(),
    poNumber: Yup.string()
      // .matches(LETTERS_NUMBERS_SYMBOLS, "Invalid Po Number")
      .test("poNumberTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "poNumber" &&
            element.isRequired === 1 &&
            element.isVisible === 1 &&
            type == "po"
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .test('poNumberTest1', 'Invalid Po Number', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "poNumber" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? LETTERS_NUMBERS_SYMBOLS.test(value) : true;
      })
      .nullable(),
    quantity: Yup.string()

      // .matches(NUMBER_REGEX, "Invalid Quantity")
      .test("quantityTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          
          return (
            element.columnName === "quantity" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });



        return !value && found.length === 1 ? false : true;
      })
      .test('quantityTest1', 'Invalid Quantity', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "quantity" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? NUMBER_TWO_DECIMAL.test(value) : true;


      })
      .nullable(),
    unitPrice: Yup.string()
      // .matches(NUMBERS_DOT_REGEX, "Invalid Unit Price")
      .test("unitPriceTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "unitPrice" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .test('unitPriceTest1', 'Invalid Unit Price', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "unitPrice" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? NUMBERS_DOT_REGEX.test(value) : true;


      })
      .nullable(),
    glAccount: Yup.string()
      .test("glAccountTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "glAccount" &&
            element.isRequired === 1 &&
            element.isVisible === 1 &&
            type != "po"
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    GLDate: Yup.date()

      .test("GLDateTest", "Required field", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "GLDate" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),



  });

  const OnaddLNS = (event) => {
    if (
      LETTERS_NUMBERS_SYMBOLS.test(event.target.value) ||
      event.target.value == ""
    ) {
      handleChange(event);
    }

    if (errors["poNumber"]) {
      handleChange(event);
    }

    // if(errors['operatingUnit']){
    //   handleChange(event);
    // }
    // if(errors['invoiceLineType']){
    //   handleChange(event);
    // }
    // else{
    //   touched[event.target.name]=true

    // }
  };

  const onAddNS = (event) => {
    if (NUMBERS_SYMBOLS.test(event.target.value) || event.target.value == "") {
      handleChange(event);
    }
    if (errors["invoiceLineNumber"]) {
      handleChange(event);
    }
  };
  const onAddN = (event) => {
    if (NUMBER_REGEX.test(event.target.value) || event.target.value == "") {
      handleChange(event);
    }
    if (errors["poLineNumber"]) {
      handleChange(event);
    }
    // if (errors["quantity"]) {
    //   handleChange(event);
    // }
  };
   
  const onAddQuantity = (event) => {
    if (
      NUMBER_TWO_DECIMAL.test(event.target.value) ||
      event.target.value == ""
    ) {
      handleChange(event);
    }

    if (errors["quantity"]) {
      handleChange(event);
    }
  };

  const onAddL = (event) => {
    if (LETTERS.test(event.target.value) || event.target.value == "") {
      handleChange(event);
    }
    if (errors["unitOfMeasure"]) {
      handleChange(event);
    }
  };
  const onAddND = (event) => {
    if (
      NUMBERS_DOT_REGEX.test(event.target.value) ||
      event.target.value == ""
    ) {
      handleChange(event);
    }
    if (errors["unitPrice"]) {
      handleChange(event);
    }
    if (errors["extendedPrice"]) {
      handleChange(event);
    }
  };

  const onAddNDUNIT = (event) => {
    if (
      NUMBER_TWO_DECIMAL.test(event.target.value) ||
      event.target.value == ""
    ) {
      handleChange(event);
    }

    if (errors["unitPrice"]) {
      handleChange(event);
    }
  };

  const onAddNDEXTEND = (event) => {
    if (
      NUMBER_TWO_DECIMAL.test(event.target.value) ||
      event.target.value == ""
    ) {
      handleChange(event);
    }

    if (errors["extendedPrice"]) {
      handleChange(event);
    }
  };

  const onchnagefunc = {
    poNumber: OnaddLNS,
    poLineNumber: onAddN,
    
    unitOfMeasure: onAddL,
    quantity: onAddQuantity,
    unitPrice: onAddNDUNIT,
    extendedPrice: onAddNDEXTEND,
    //operatingUnit:OnaddLNS,
    invoiceLineNumber: onAddNS,
    //invoiceLineType:OnaddLNS
  };
  const saveFormValues = async (values) => {
    
    setLoading(true);
    setDisableSubmit(true);
    const config = {
      method: flag ? "POST" : "PUT",
      url: flag ? INVOICELINE_CREATE_URL : INVOICE_LINE_UPDATE_URL,
      // data: { ...values, invoiceId: invoiceID

      // },
      data: {
        webString: enCryptFun(
          JSON.stringify({
            ...values,
            invoiceId: invoiceID,
            // GLDate:
            //   values.GLDate != null
            //     ? moment(new Date(values.GLDate)).format("yyyy/MM/DD")
            //     : "",
            // GLDate:
            //   ![null, ""].includes(values.GLDate)
            //     ? moment(new Date(values.GLDate)).format("yyyy/MM/DD")
            //     : "",
            // glAccount: values.glAccount === "" ? "" : values.glAccount,
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };

    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);


      // Swal.fire(
      //   getAlertToast(
      //     "success",
      // flag
      //   ? "Invoice Line created successfully!"
      //   : "Invoice Line Update successfully!"
      //   )
      // );
      // CustomMessage(
      //   flag
      //     ? "Invoice Line created successfully!"
      //     : "Invoice Line Update successfully!",
      //   "success",
      //   enqueueSnackbar
      // );
      if (flag) {


        var secondBetweenTwoDate = Math.abs(
          (new Date().getTime() - timeproccess.getTime()) / 60000
        );
        invTime(secondBetweenTwoDate);
        saveAudit(`Line Item Created`);
        saveAudit(`Edit`);
      } else {
        invoiceToggle(resetForm({}));

        // saveAudit("Invoice sssline");
      }

      setGlDate("");
      CustomMessage(
        flag
          ? "New line created"
          : "Invoice line details saved",
        "success",
        enqueueSnackbar
      ); //call toster

      resetForm();
      submitCallBack();
      if (!flag && indexvalue < invoiceListlen) {
        editInvoiceLine();
      }



    } catch (error) {
      // let { data } = error.response;
      // Swal.fire(getAlert("error", data));
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
      setGlDate("");
    }
  };

  const GetRequiredValues = async () => {
    const config = {
      method: "GET",
      url: GET_INVOICE_FIELDS,
      params: {
        // teamId: getRole() == "Supplier" ? subTeamId : getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getRole() == "Supplier" ? subTeamId : getTeamID(),
          })
        ),
        flutterString: "",
      },

      headers: authHeader(),
    };
    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        // let requiredFields = data.filter((d) => { return d.isMandatory === 1});
        
        setRequiredFieldList(data);
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  //Formik
  
  const {
    handleSubmit,
    handleChange,
    values,
    setValues,
    errors,
    touched,
    resetForm,
    handleBlur,
  } = useFormik({
    initialValues: {
      ...formData,
      glAccount:
        (formData.glAccount == "" || formData.glAccount == null) &&
          flag &&
          type == "nonpo"
          ? GlCode
          : formData.glAccount,
    },
    validateOnMount: flag ? false : true,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => saveFormValues(values),
  });

  const handleGlDate = (e) => {
    setValues((val) => ({ ...val, GLDate: e }));
    if (e === null) {
      // if (requiredFieldList[11].isRequired === 1) setGlDateRequiredError("Required field")
      // else setGlDateRequiredError("");

      var found = requiredFieldList.filter(function (element) {
        return (
          element.columnName === "GLDate" &&
          element.isRequired === 1 &&
          element.isVisible === 1
        );
      });
      if (found.length === 1) setGlDateRequiredError("Required field")
      else setGlDateRequiredError("");
    }
    else {
      setGlDateRequiredError("");
    }
  };

  const handleGlError = (e) => {
    if (e === "invalidDate" || e === "minDate" || e === "maxDate") {
      setGlDateValidationError("Invalid Date");
    }
    else setGlDateValidationError("");
  }

  useEffect(() => {
    GetRequiredValues();
  }, [subTeamId]);

  useEffect(() => {
    
    setDisableSubmit(true);
    let booleanValue = false;
    for (let [key, value] of Object.entries(values)) {
      if (value !== "invoiceLineId" || value !== "invoiceId") {
        if (value !== '' && value !== null) {
          setDisableSubmit(false);
          break;
        }
      }
    }
  }, [values])

  useEffect(() => {
    if (gLDateRequiredError !== "" && gLDateRequiredError !== false) {
      setGlDateStyles({
        width: "100% !important",
        "& .MuiInputLabel-root": { color: 'red' },
        "& .MuiOutlinedInput-root": {
          "& fieldset": { borderColor: "red" },
          "&.Mui-focused fieldset": { borderColor: "red" },
          "&:hover fieldset": { borderColor: "red" }
        },
      })
    }
    else setGlDateStyles({ width: "100% !important" })
  }, [gLDateRequiredError])

  const op = requiredFieldList.filter(
    (element) =>
      element.columnName === "operatingUnit" && element.isMandatory === 1
  );
  const lineAmountRequired = requiredFieldList.filter(
    (element) =>
      element.columnName === "operatingUnit" && element.isMandatory === 1
  );

  

  let backColor = () => {
    if (
      loading === true ||
      Object.values(values).every((x) => x === null || x === "")
    ) {
      return "#f0f0f0";
    } else {
      return "rgb(236, 124, 55)";
    }
  };

  
  let checkDisable = useMemo(() => {
    let checkable = false;
    let count = 0;
    let data = { ...values };
    Object.entries(data).forEach((x) => {
      if (x[0] === "GLDate" && x[1] === "") {
        checkable = true;
      } else if (
        x[0] !== "GLDate" &&
        x[0] !== "invoiceId" &&
        x[0] !== "invoiceLineId" &&
        x[1] !== ""
      ) {
        count = count + 1;
      }
    });
    return checkable || !(count > 0);
  }, [values]);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);

    //setPageNumber(1);
  }
  const [page, setPage] = React.useState(1);
  const handleChangepage = (event, value) => {
    
    setPage(value);
    setPageNumber(value);
  };

  const closeModel = () => {
    if (flag) {
      var secondBetweenTwoDate = Math.abs(
        (new Date().getTime() - timeproccess.getTime()) / 60000
      );
      invTime(secondBetweenTwoDate);
    }
    invoiceToggle(resetForm({}));
  };

  const invTime = async (processTime) => {
    const getconfig = {
      method: "PUT",
      url: UPDATE_INV_PROCESS_TIME,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
            invProcessingTime: processTime.toFixed(4),
            teamId: getTeamID(),
            userId: getUserId(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(getconfig);
      let l = deCryptFun(response.data);

      const { message } = JSON.parse(l);

      if (message === "Success") {
      }
    } catch (err) { }
  };
  const saveAudit = async (comment) => {
    const config = {
      method: "PUT",
      url: SAVE_AUDIT,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
            comments: comment,
            actionBy: getUserId(),
            isExceptionResolved: false,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, message } = JSON.parse(l);
      if (message === "Success") {
        getAudit();
      }
    } catch (error) { }
  };

  
  
  
  // flag ? invoiceListlen + 1 : values[columnName]
  //   ? values[columnName] : values.lineNumber ? values.lineNumber : ""


  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={createInvoiceModal}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1400px",  // Set your width here
            },
          },
        }}
        // onClose={() => invoiceToggle(resetForm({}))}
        aria-labelledby="responsive-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => closeModel()}
        >
          {flag ? "Add Invoice Line Item" : "Update Invoice Line Item"}
        </BootstrapDialogTitle>

        <DialogContent style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form form="true" onSubmit={handleSubmit}>
                {requiredFieldList &&
                  stableSort(
                    requiredFieldList.filter(
                      (i) => i.moduleName == "Invoice Line"
                    ),
                    getSorting("asc", "fieldOrder")
                  ).map((record, index) => {
                    const { columnName } = record;
                    //  
                    return requiredFieldList.filter(
                      (element) =>
                        element.columnName === record.columnName &&
                        element.isVisible === 1 &&
                        (type == "nonpo"
                          ? element.columnName != "poLineNumber" &&
                          element.columnName != "poNumber"

                          : element.columnName != "glAccount")
                    ).length == 1 ? (
                      columnName == "GLDate" ? (
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            gutterBottom
                            sx={{ fontWeight: 600, color: "#4E4B66" }}
                          >
                            {record.fieldName}{" "}
                            {requiredFieldList.filter(
                              (element) =>
                                element.columnName === columnName &&
                                element.isRequired === 1
                            ).length == 1
                              ? "*"
                              : ""}
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack
                              sx={{ display: "flex", flexDirection: "row", borderColor: "red" }}
                            >
                              <DesktopDatePicker
                                allowSameDateSelection
                                closeOnScroll={true}
                                name="GLDate"
                                value={values.GLDate ? new Date(values.GLDate) : null}
                                onChange={handleGlDate}
                                disabled={dialogueView == "viewLineItem"}
                                onError={handleGlError}
                                error={gLDateRequiredError !== "" ? true : false}
                                onBlur={handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={glDateStyles}
                                    helperText={gLDateValidationError}
                                  />
                                )}
                              />
                            </Stack>
                          </LocalizationProvider>
                          <FormHelperText id="standard-error-helper-text" error>
                            {gLDateRequiredError}
                          </FormHelperText>
                        </Grid>
                      ) :
                        columnName == "poLineNumber" ? (
                          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: 600, color: "#4E4B66" }}
                            >
                              {record.fieldName}{" "}
                              {requiredFieldList.filter(
                                (element) =>
                                  element.columnName === columnName &&
                                  element.isRequired === 1
                              ).length == 1
                                ? "*"
                                : ""}
                            </Typography>
                            <CssTextField
                              fullWidth
                              name={columnName}
                              id={columnName}
                              placeholder={`Enter ${record.fieldName}`}
                              // invalid={
                              //    errors[record.columnName]? true
                              //    : false

                              //   // touched[record.columnName]

                              // }
                              error={errors[columnName]}
                              value={
                                // flag ? invoiceListlen + 1 : values[columnName]
                                //   ? values[columnName] : values.lineNumber ? values.lineNumber : ""
                                values[columnName] ? values[columnName] : values.poLineNumber ? values.poLineNumber : ""
                              }
                              // onBlur={handleBlur}
                              onChange={onchnagefunc[columnName] || handleChange}
                              disabled={dialogueView == "viewLineItem"}
                            />
                            <FormHelperText id="standard-error-helper-text" error>
                              {errors[columnName] ? errors[columnName] : ""}
                            </FormHelperText>
                          </Grid>
                        ) : columnName == "invoiceLineNumber" ? (
                          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: 600, color: "#4E4B66" }}
                            >
                              {record.fieldName}{" "}
                              {requiredFieldList.filter(
                                (element) =>
                                  element.columnName === columnName &&
                                  element.isRequired === 1
                              ).length == 1
                                ? "*"
                                : ""}
                            </Typography>
                            <CssTextField
                              fullWidth
                              name={columnName}
                              id={columnName}
                              placeholder={`Enter ${record.fieldName}`}
                              // invalid={
                              //    errors[record.columnName]? true
                              //    : false
  
                              //   // touched[record.columnName]
  
                              // }
                              error={errors[columnName]}
                              value={
                                // flag ? invoiceListlen + 1 : values[columnName]
                                //   ? values[columnName] : values.lineNumber ? values.lineNumber : ""
                                values[columnName]
                                  ? values[columnName]
                                  : values.invoiceLineNumber
                                  ? values.invoiceLineNumber
                                  : ""
                              }
                              // onBlur={handleBlur}
                              onChange={onchnagefunc[columnName] || handleChange}
                              disabled={dialogueView == "viewLineItem"}
                            />
                            <FormHelperText id="standard-error-helper-text" error>
                              {errors[columnName] ? errors[columnName] : ""}
                            </FormHelperText>
                          </Grid>
                        ):columnName == "glAccount" ? (
                            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                              <Typography
                                gutterBottom
                                sx={{ fontWeight: 600, color: "#4E4B66" }}
                              >
                                {record.fieldName}{" "}
                              </Typography>

                              <CssTextField
                                fullWidth
                                name={columnName}
                                id={columnName}
                                placeholder={`Enter ${columnName}`}

                                value={


                                  values[columnName] ? values[columnName] : ""
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={dialogueView == "viewLineItem"}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ width: "100% !important" }}
                                  // helperText={gLDateError ? "Invalid Date" : ""}
                                  />
                                )}
                              />
                            </Grid>)
                            : (
                              <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                                <Typography
                                  gutterBottom
                                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                                >
                                  {record.fieldName}{" "}
                                  {requiredFieldList.filter(
                                    (element) =>
                                      element.columnName === columnName &&
                                      element.isRequired === 1
                                  ).length == 1
                                    ? "*"
                                    : ""}
                                </Typography>
                                <CssTextField
                                  fullWidth
                                  name={columnName}
                                  id={columnName}
                                  placeholder={`Enter ${record.fieldName}`}
                                  // invalid={
                                  //    errors[record.columnName]? true
                                  //    : false

                                  //   // touched[record.columnName]

                                  // }
                                  error={errors[columnName]}
                                  value={
                                    values[columnName]
                                      ? values[columnName]
                                      : columnName == "glAccount"
                                        ? GlCode
                                        : ""
                                  }
                                  // onBlur={handleBlur}
                                  onChange={onchnagefunc[columnName] || handleChange}
                                  disabled={dialogueView == "viewLineItem"}
                                />
                                <FormHelperText id="standard-error-helper-text" error>
                                  {errors[columnName] ? errors[columnName] : ""}
                                </FormHelperText>
                              </Grid>
                            )
                    ) : (
                      ""
                    );
                  })}

                {/* {type === "nonpo" &&
                  requiredFieldList &&
                  requiredFieldList.filter(
                    (element) => element.columnName === "glAccount"
                  ).length == 1 &&
                  requiredFieldList
                    .filter((element) => element.columnName === "glAccount")
                    .map((el) => {
                      const { columnName, fieldName } = el;

                      return (
                        <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                          <Typography
                            gutterBottom
                            sx={{ fontWeight: 600, color: "#4E4B66" }}
                          >
                            {fieldName}{" "}
                          </Typography>

                          <CssTextField
                            fullWidth
                            name={columnName}
                            id={columnName}
                            placeholder={`Enter ${columnName}`}
                            // invalid={
                            //   errors[columnName]
                            //     ? true
                            //     : false
                            // }
                            value={
                              // columnName == "glAccount"
                              //   ? GlCode
                              //   : values[columnName]
                              //   ? values[columnName]
                              //   : ""

                              values[columnName] ? values[columnName] : ""
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            disabled={dialogueView == "viewLineItem"}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ width: "100% !important" }}
                                // helperText={gLDateError ? "Invalid Date" : ""}
                              />
                            )}
                          />
                        </Grid>
                      );
                    })} */}

                <DialogActions>
                  <Button onClick={() => closeModel()}>Close</Button>
                  {dialogueView == "viewLineItem" ? null : (
                    <Button
                      color="primary"
                      type="submit"
                      style={disableSubmit
                        ? { color: "#f0f0f0", backgroundColor: "#A0A3BD", width: "100px", height: "43px" }
                        : { color: "#fff", backgroundColor: "rgb(236, 124, 55)", width: "100px", height: "43px" }}
                      disabled={disableSubmit}
                    //   gLDateError ||
                    //   loading === true ||
                    //   //  checkDisable
                    //   Object.values(values).every(
                    //     (x) => x === null || x === ""
                    //   )
                    // }
                    // sx={{
                    //   "&:disabled": {
                    //     backgroundColor: "#A0A3BD !important",
                    //   },
                    // }}
                    >
                      {flag ? "Add" : "Update"}
                    </Button>
                  )}
                </DialogActions>
              </Form>
            </Grid>

            <Grid item xs={6}>
              <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                <TransformWrapper
                  options={
                    extensionOfUrl == "pdf"|| "PDF"
                      ? transformOptionsPdf
                      : transformOptionsPng
                  }
                >
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <Fragment>
                      {flag && (
                        <>
                          <Typography variant="h4">Invoice</Typography>
                          <Box className={styleclasses.tools}>
                            <IconButton
                              aria-label="add"
                              className={styleclasses.previewbtn}
                            >
                              <AddCircleOutlineRoundedIcon onClick={zoomIn} />
                            </IconButton>
                            <IconButton
                              aria-label="out"
                              className={styleclasses.previewbtn}
                            >
                              <RemoveCircleOutlineRoundedIcon
                                onClick={zoomOut}
                              />
                            </IconButton>
                          </Box>
                        </>
                      )}

                      {/* <canvas id="myCanvas" height="100" weight="100"></canvas> */}
                      <TransformComponent>
                        {/* {url && ( */}
                        {extensionOfUrl == "pdf"|| extensionOfUrl == "PDF"? (
                          <Document
                            className="popuppdf"
                            file={previewUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            // onLoadError={onDocumentError}
                            overflow="scroll"
                            width="100"
                            noData="Loading PDF..."
                          >
                            <Page
                              pageNumber={pageNumber}
                            // width="100"
                            // overflow="scroll"
                            />
                          </Document>
                        ) : (
                          <img
                            className="pdfview"
                            style={{ width: "100%" }}
                            src={previewUrl}
                            noData="Loading File..."
                          ></img>
                        )}
                      </TransformComponent>
                    </Fragment>
                  )}
                </TransformWrapper>
                {numPages ? (
                  <Stack spacing={2}>
                    <Pagination
                      count={numPages}
                      page={page}
                      onChange={handleChangepage}
                    />
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}