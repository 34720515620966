import {
    Typography,
    Button,
    Input,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    Box,
    Snackbar,
    Alert,
  } from "@mui/material";
  import React, { useState } from "react";
  import FormControl from "@mui/material/FormControl";
  import NativeSelect from "@mui/material/NativeSelect";
  import InputBase from "@mui/material/InputBase";
  import PropTypes from "prop-types";
  import { styled } from "@mui/material/styles";
  import CloseIcon from "@mui/icons-material/Close";
  import TextField from "@mui/material/TextField";
  import Grid from "@mui/material/Grid";
  import { makeStyles } from "@mui/styles";
  import * as Yup from "yup";
  import { useFormik } from "formik";
  import Checkbox from "@mui/material/Checkbox";
  import FormControlLabel from "@mui/material/FormControlLabel";
  
  import {
    deCryptFun,
    enCryptFun,
    getEmail,
    getRole,
    getSorting,
    getTeamID,
    stableSort,
    getUserId,
  } from "../../comman/function";
  import API from "../../constants/API";
  import currencies from "../../commonCurrency.json";
  import { authHeader, getAlertToast, getAlert } from "../../comman/mainfunction";
  import { ADD_SUPPLIER_SITE_URL } from "../../comman/url";
  import Swal from "sweetalert2";
  import { useSnackbar } from "notistack";
  import { CustomMessage } from "../../comman/helperfunction";
  import { set } from "date-fns/esm";
  
  const useStyles = makeStyles((theme) => ({
    BootstrapDialog: {
      width: 600,
    },
    canceldialog: {
      border: "1px solid #EC7C37 !important",
      color: "#EC7C37 !important",
      margin: "8px",
      textTransform: "none !important",
      padding: "8px 16px",
  
      "&:hover": {
        border: "1px solid #EC7C37 !important",
        backgroundColor: "#F6F6F680 !important",
        color: "#EC7C37 !important",
      },
    },
    submitdialog: {
      backgroundColor: " #EC7C37 !important",
      color: "white !important",
      margin: "8px",
      padding: "8px 16px",
      textTransform: "none !important",
      "&:hover": {
        backgroundColor: "#D25D15 !important",
        color: "white !important",
      },
    },
  }));
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      position: "relative",
      border: "1px solid #B4B4B4 !important",
      fontSize: 16,
      padding: "16.5px 14px",
      borderRadius: "8px",
  
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        backgroundColor: "white",
        border: "1px solid #14142A !important",
        borderRadius: "8px",
      },
    },
  }));
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#B4B4B4",
      },
      "&:hover fieldset": {
        borderColor: "#14142A",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#14142A",
      },
    },
  });
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
      width: "100%",
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  
  const AddSiteSupplierModal = ({
    open,
    addToggleClose,
    successReqInviteSupplierCallBack,
    requestListing,
    supplierID,
  }) => {
    const styleclasses = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = React.useState(false);
    const [checkData, setChekData] = useState("")
  
    const validationSchema = Yup.object({
      siteName: Yup.string()
        .required("Required")
        .trim(/^(\S+$)/g, "* This field cannot contain only blankspaces"),
  
      siteAddress: Yup.string()
        .required("Required")
        .trim(/^(\S+$)/g, "* This field cannot contain only blankspaces"),
  
      currency: Yup.string()
        .required("Required")
        .trim(/^(\S+$)/g, "* This field cannot contain only blankspaces"),
  
      operatingUnit: Yup.string()
        .required("Required")
        .trim(/^(\S+$)/g, "* This field cannot contain only blankspaces"),
    });
  
    const saveFormValues = async (values) => {
      setLoading(true);
      const config = {
        method: "POST",
        url: ADD_SUPPLIER_SITE_URL,
        headers: authHeader(),
        data: {
          // ...values,
          // teamId: getTeamID(),
          // requestedBy: getUserId(),
          webString: enCryptFun(
            JSON.stringify({
              ...values,
              supplierId: supplierID,
            })
          ),
          flutterString: "",
        },
      };
      try {
        const response = await API(config);
        //const { status, message } = response.data;
        let l = deCryptFun(response.data);
  
        const { status, message } = JSON.parse(l);
        if (status === "Success") {
          CustomMessage(
            "Supplier site added",
            "success",
            enqueueSnackbar
          );
        } else {
          CustomMessage(message, "error", enqueueSnackbar); //call toster
        }
      } catch (error) {
        if (error.response) {
          let { data } = error.response;
  
          let p = deCryptFun(data);
          let v = JSON.parse(p);
  
          // Swal.fire("Error", v.message);
          CustomMessage(v.message, "error", enqueueSnackbar); //call toster
        }
      } finally {
        successReqInviteSupplierCallBack();
        setLoading(false);
      }
    };
  
    const { handleSubmit, handleChange, values, errors, touched, setFieldError } =
      useFormik({
        initialValues: {
          siteName: "",
          siteAddress: "",
          currency: "",
          operatingUnit: "",
        },
        validationSchema,
        onSubmit: (values) => {
          saveFormValues(values);
        },
      });
      const dropdownupdate=(e)=>{
        console.log(e)
      }
    
  
    const handleChange2 = (event) => {
      setChecked(event.target.checked);
    };
  
    const supplierEmailHandler = (e) => {
      setChecked(false);
      handleChange(e);
    };
  
    return (
      <>
        <BootstrapDialog
          // onClose={addToggleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={addToggleClose}
            className={styleclasses.BootstrapDialog}
            sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
          >
            Add New Supplier Site
          </BootstrapDialogTitle>
          <Box component={"form"} onSubmit={handleSubmit}>
            <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
              <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Site Name
                </Typography>
                <CssTextField
                  fullWidth
                  id="custom-css-outlined-input"
                  value={values.siteName}
                  onChange={handleChange}
                  name="siteName"
                  placeholder="Enter Site Name"
                />
                <Typography style={{ color: "red" }}>
                  {errors.siteName && touched.siteName ? errors.siteName : ""}
                </Typography>
              </Grid>
  
              <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Site Address
                </Typography>
                <CssTextField
                  fullWidth
                  id="custom-css-outlined-input"
                  value={values.siteAddress}
                  onChange={handleChange}
                  name="siteAddress"
                  placeholder="Enter Site Address"
                />
                <Typography style={{ color: "red" }}>
                  {errors.siteAddress && touched.siteAddress
                    ? errors.siteAddress
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Currency
                </Typography>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    style={{ fontFamily: "Montserrat" }}
                    id="demo-customized-select-native"
                    name="currency"
                    value={values.currency}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                  >
                    {/* <option aria-label="None" value="" /> */}
                    {/* <=> {option value={""}>All</option>
                    {[
                      
  
                    ].map(()  */}
                    <option value={""}>Select Currency</option>
                    {Object.keys(currencies).map((currency, index) => {
                      return (
                        <option value={currency.code} key={index}>
                          {currency}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                {/* <CssTextField
                  fullWidth
                  id="custom-css-outlined-input"
                  value={values.currency}
                  onChange={handleChange}
                  name="currency"
                  placeholder="Enter Currency"
                /> */}
                {/* <Typography style={{ color: "red" }}>
                  {errors.currency && touched.currency
                    ? errors.currency
                    : ""}
                </Typography> */}
              </Grid>
  
              <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Operating Unit
                </Typography>
                <CssTextField
                  fullWidth
                  id="custom-css-outlined-input"
                  value={values.operatingUnit}
                  onChange={handleChange}
                  name="operatingUnit"
                  placeholder="Enter Operating Unit"
                />
                <Typography style={{ color: "red" }}>
                  {errors.operatingUnit && touched.operatingUnit
                    ? errors.operatingUnit
                    : ""}
                </Typography>
              </Grid>
            </DialogContent>
            <DialogActions>
              <DialogActions>
                <Button
                  onClick={addToggleClose}
                  className={styleclasses.canceldialog}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className={styleclasses.submitdialog}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </DialogActions>
            </DialogActions>
          </Box>
        </BootstrapDialog>
      </>
    );
  };
  
  export default AddSiteSupplierModal;
  