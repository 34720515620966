const crypto = require("crypto");

export function Name(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export function getEmail() {
  const email = localStorage.getItem("EMAIL");
  const email1 = email ? deCryptFun(email) : "";
  return email1;
}
export function getIsExceptionRole() {
  const isException = localStorage.getItem("isException");
  const isException1 = isException ? deCryptFun(isException) : "";
  if (isException1 == 0) {
    return 0;
  } else {
    return 1;
  }
}

export function getSenderEmail() {
  const email = localStorage.getItem("SENDER_EMAIL");
  const email2 = email ? deCryptFun(email) : "";
  return (email2);
}

export function getTimeFormat(){  
  let apiTime = localStorage.getItem('API_TIME')
  const apiTime1 = apiTime ? deCryptFun(apiTime) : "";
  return apiTime1;
}
export function getAuthToken(){
  const authToken = localStorage?.getItem('AUTH_TOKEN');
  const authToken1 = authToken ? deCryptFun(authToken) : "";
  return (authToken1)
}

export function getUserId() {
  const userId = localStorage.getItem("User_ID");
  const userId1 = userId ? parseInt(deCryptFun(userId)) : "" ; 
  return userId1;
}

export function getSupplierMaintananceEmail(){
  const supplierMaintanance1 = localStorage.getItem('Suppliermaintanceemail') ;
  const supplierMaintanance2 = supplierMaintanance1 ? deCryptFun(supplierMaintanance1) : "";
  return(supplierMaintanance2);
}
export function getDeviceID(){ 
  let deviceId = localStorage.getItem('Device_ID')
  const deviceId1 = deviceId ? deCryptFun(deviceId) : "";
  return (deviceId1);
}

export function getSubTeamId() {
  const Id = localStorage.getItem("TEAM_SUB_ID");
  const Id1 = Id ? deCryptFun(Id) : "";
  return Id1;
}
export function getProfilePic() {
  const pic = localStorage.getItem("PROFILE_PIC");
  const pic1 = pic ? deCryptFun(pic) : "";
  return pic1;
}

export function getTeamID() {
  const teamId = localStorage.getItem("Team_ID");
  const teamId1 = teamId ? parseInt(deCryptFun(teamId)) : "";
  return teamId1;
}

export function getBrandLogo() {
  const brandLogo = localStorage.getItem("BRAND_LOGO");
  const brandLogo1 = brandLogo ? deCryptFun(brandLogo) : "";
  return brandLogo1;
}

export function getRole() {
  const role = localStorage.getItem("USER_ROLE");
  const role1 = role ? deCryptFun(role) : "";
  console.log(role1,"userrole");
  return (role1);
}
export function getLoginName() {
  const name = localStorage.getItem("LOGIN_NAME");
  const name1 = name ? deCryptFun(name) : "";
  return name1;
}
export function getFullYear() {
  return new Date().getFullYear();
  // return "2021";
}

export function getInvoiceID(){ 
  let invid = localStorage.getItem('INVOICE_ID')
  const iid = invid ? parseInt(deCryptFun(invid)) : "";
  return iid;
}

export function getInvoiceCount() {
  const count = localStorage.getItem("INVOICE_COUNT");
  const count1 = count ? parseInt(count) : "";
  return (count1);
}

export function getApprovalAmount() {
  const count = localStorage.getItem("APPROVAL_AMOUNT");
  const count1 = count ? parseInt(deCryptFun(count)) : "";
  return (count1);
}
export function getIsAddSupplier(){
  const isAddSupplier = localStorage.getItem("isAddSupplier");
  const isAddSupplier1 = isAddSupplier ? deCryptFun(isAddSupplier) : "";
  return isAddSupplier1;
}
// Common Table Sorting Method
const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getSorting = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
};
// const crypto = require("crypto");
// const masterKey = "EZCloud@123!";
// const encryptionType = "aes-256-cbc";
// const encodingType = "utf8";
export function enCryptFun(text) {
  var cipher = crypto?.createCipher(process.env.REACT_APP_ALGORITHAM, process.env.REACT_APP_MASTERKEY);
  var crypted = cipher?.update(text, process.env.REACT_APP_IV, "hex");
  crypted += cipher?.final("hex");
  return crypted;
}

export function deCryptFun(text) {
  var decipher = crypto?.createDecipher(process.env.REACT_APP_ALGORITHAM, process.env.REACT_APP_MASTERKEY);
  var decrypted = decipher?.update(text, "hex", process.env.REACT_APP_IV);
  decrypted += decipher?.final(process.env.REACT_APP_IV);
  return decrypted;
}

export const JSONParser = (str, def) => {
  try {
    return JSON.parse(str) || def;
  } catch (e) {
    console.warn(e);
    return def;
  }
};

export const twoFAuth = () => {
  return (parseInt(deCryptFun(localStorage.getItem("AUTH_2F"))));
};
