import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import React from "react";
import {
  DASHBOARD_ROUTE,
  INVOICEPREVIEW_ROUTE,
  INVOICE_ROUTE,
  METRICSINVOICE_ROUTE,
  MYTEAM_ROUTE,
  SETTINGS_ROUTE,
  SIDEBAR_ROUTE,
  SIGNIN_ROUTE,
  FORGOTPASSWORD_ROUTE,
  SIGNUP_ROUTE,
  SUPPLIERMANAGEMENT_ROUTE,
  AUTHENTIFICATION_ROUTE,
  UPDATE_PASSWORD_ROUTE,
  REGISTERED_ROUTE,
  VERIFY_EMAIL_ROUTE,
} from "./constants/RoutePaths";
import Signin from "./components/Signin";
import Sidebar from "./components/Sidebar";
import Layouts from "./components/Layouts";
import Dashboard from "./components/Dashboard";
import Invoice from "./components/Invoice";
import InvoicePreview from "./components/InvoicePreview";
import MyTeam from "./components/MyTeam";
import SupplierManagement from "./components/SupplierManagement";
import Settings from "./components/Settings";
import MetricsInvoice from "./components/MetricsInvoice";
import Forgotpassword from "./components/Forgotpassword";
import Authentification from "./components/Authentification";
import Signup from "./components/Signup";
import NotFound from "./components/NotFound";
import { PrivateRoute, PublicRoute } from "./comman/mainfunction";
import Updatepassword from "./components/Updatepassword";
import Registered from "./components/Registered";
import VerifyEmail from "./components/VerifyEmail";
import { getAuthToken } from "./comman/function";
function App() {
  const token = getAuthToken();

  return (
    <div className="App">
      <Routes>
        <Route
          index
          path="/"
          element={
            token ? (
              <Navigate to={DASHBOARD_ROUTE} />
            ) : (
              <Navigate to={SIGNIN_ROUTE} />
            )
          }
        ></Route>
        <Route
          path="/*"
          index
          element={
            <PrivateRoute>
              <Layouts />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={SIGNIN_ROUTE}
          element={
            <PublicRoute>
              <Signin />
            </PublicRoute>
          }
        ></Route>

        <Route path={REGISTERED_ROUTE} element={<Registered />}></Route>
        <Route path={VERIFY_EMAIL_ROUTE} element={<VerifyEmail />}></Route>
        <Route
          path={FORGOTPASSWORD_ROUTE}
          element={
            <PublicRoute>
              <Forgotpassword />
            </PublicRoute>
          }
        ></Route>
        <Route
          path={AUTHENTIFICATION_ROUTE}
          element={
            <PublicRoute>
              <Authentification />
            </PublicRoute>
          }
        ></Route>
        <Route
          path={UPDATE_PASSWORD_ROUTE}
          element={<Updatepassword />}
        ></Route>
        <Route
          path={SIGNUP_ROUTE}
          element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          }
        ></Route>
        <Route path="pagenotfound" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
}

export default App;
